
.header {
	position: relative;
	background-color: #d9241b;

	&__guide {
		position: absolute;
		z-index: 4;
		left: 50%;
		top: 100%;
		width: 66px;
		height: 100px;
		transform: translate(-33px, -50px);
		background-color: #187bca;
		color: #ffffff;
		border-radius: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 0 0 0 12px rgba(#000000, .28);
		transition: box-shadow .6s ease-out;
	}

	&__guide-icon {
		fill: currentColor;
		transition: transform .5s ease-out;
	}

	@include link(".header__guide") {
		color: #ffffff;
		text-decoration: none;
	}

	@include link-over(".header__guide") {
		box-shadow: 0 0 0 36px rgba(#000000, .01);
		color: #ffffff;

		.header__guide-icon {
			transform: translateY(.5em);
		}
	}

	&__title {
		position: relative;
	}

	&__home-link {
		@include fill;
	}

	&__content {
		position: relative;
		z-index: 2;

		@include bp("> 800px") {
			@include flex(df, fww, jcsb, aic);
		}
	}

	&__heading {
		color: #ffffff;
		line-height: 1;
		padding-top: .4em;
		padding-bottom: .5em;
		position: relative;
		margin: {
			top: 0;
			bottom: 0;
		}
		font: {
			size: rem(28px);
			weight: bold;
		}
	}

	&__subheading {
		margin-top: .5em;
		margin-bottom: .15em;
		font: {
			weight: normal;
		}
	}

	&.-home-header {
		margin-bottom: 5%;

		.header__heading {
			font-size: rem(40px);
			width: 10em;
		}

		.header__subheading {
			display: block;
			font-size: rem(20px);
		}

		.header__content {
			display: flex;
			flex-direction: column;

			@include bp("> 800px") {
				justify-content: space-between;
				align-items: flex-start;
                flex-direction: row;
                //max-width: 1860px;
			}
		}
	}

	&.-page-header {
		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 0;
			padding-bottom: ratio(1920px, 354px);
			background: $color-dark url(/images/local/v-202103291600/header.jpg) no-repeat 50% 50% / cover;
		}

		.header__heading {
			@include min(42em) {
				width: 20em;
				display: flex;
				align-items: center;
			}
		}

		.header__heading,
		.header__subheading {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&__logo {
		float: left;
		margin: 0 1em 0;
		font-size: 1rem;
	}

	&__home-link {
		color: inherit;
		text-decoration-color: none;
	}

	&__banner {
		text-align: center;
	}

	&__banner-image {
		max-width: 90%;
		margin-bottom: -17%;
	}

	&__banner-text {
		color: #ffffff;
		font-size: rem(25px);
		font-weight: 300;
		margin: 2.5em 0 0;
		font-family: $font-headings;
		text-shadow: 0 2px 15px rgba(#000000, .6);

		@include min(1200px) {
			font-size: rem(40px);
		}

		.massive {
			display: block;
			font-weight: bold;
			font-size: rem(55px);
			text-transform: uppercase;
			line-height: .75;

			@include min(1200px) {
				font-size: rem(90px);
			}
		}
	}
}
