
.main-nav {
	flex-shrink: 0;
    overflow: hidden;
	color: $color-dark;
	padding: 0;
    order: 1;
    width: 100%;
	&__heading {
		margin-top: .5em;
	}

	.nav {
		@include flex(df, jcsa, fww);
		padding: 0;
        margin: .5em 0 0em;

		&__item {
			display: inline-block;
			flex-grow: 1;
			margin: 0;
			text-align: center;
			font-weight: 500;
			font-size: rem(20px);
			font-family: $font-headings;
			text-transform: uppercase;

            .info-hidden,
            .info-concept{
                vertical-align: top;
            }

			&:not(:first-child) {
				margin-left: 30px;
			}
		}

		&__link {
			display: inline-block;
			padding-bottom: 16px;
			overflow: hidden;
			position: relative;

			&::after {
				content: "";
				position: absolute;
				left: 50%;
				bottom: -5px;
				width: 40%;
				height: 5px;
				background-color: #ffffff;
				transform: translateX(-50%);
				transition: transform .15s ease-out;
				border-radius: 10px;
			}

			@include link(&) {
				color: #ffffff;
				text-decoration: none;
			}

			@include link-over(&) {
				color: #ffffff;
				text-decoration: none;

				&::after {
					transform: translate(-50%, -5px);
				}
			}

			&.-active {
				text-decoration: none;
				cursor: default;
				pointer-events: none;
			}
        }
    }
    @include min(1300px){
        order: 0;
        width: auto;
        overflow: auto;
        .nav {
            margin: 3em 0 1em;
            &__item {
                &:not(:first-child) {
                    margin-left: 50px;
                }
            }
        }
    }
}
