
.search {
	@include flex(df, ais, fg0);
    padding: 1em 0 1em;
    @include min(600px){
        padding: 1em 0 1em 1em;
        &__form {
            flex-grow: 0;
        }
        &__input{
            width: 12.5em;
        }
    }
    @include min(1024px){
        align-self: center;
    }
	&__form {
        position: relative;
        display: flex;
        flex-grow: 1;
	}

	&__label {
        display: block;
	}

	&__input,
	&__button {
		border-radius: 4px;
	}

	&__input {
        height: 40px!important;
        flex-grow: 1;
        transition: border-color .15s ease;
        padding: 5px 5px 5px 1rem;
        padding-left: 20px!important;
        padding-right: 35px!important;
        background-position-X: 16px!important;
        &::placeholder{
            opacity: 0;
            color: #ffffff;
        }

		&:focus {
			border-color: seagreen;
		}
	}

	&__button {
		color: $color-dark;
        &[type=submit]{
            margin-left: 1em;
            padding-top: 0.5em;
            padding-bottom: 0.5em;
            background-color: $color-secondary;
            transition: background-color 0.5s ease;
            @include link-over(&){
                background-color: lightgrey;
            }
        }
        &[type=image]{
            position: absolute;
            background-color: transparent;
            padding: 0;
            height: 18px;
            width: 18px;
            border: 0;
            right: 14px;
            top: 50%;
            margin: -9px 0 0;
        }
    }
    .-hidden{
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}
