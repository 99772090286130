
/*!
*  Author: WEBHOUSE, s. r. o.
*  Version: 1.0
*/


/*
* BASE DEPENDENCIES
*/

@import
	"ecco";

/*
* SHARED
*/

@import
	"shared/vars",
	"shared/animations",
	"shared/typography",
	"shared/helpers",
	"shared/overrides";

/*
* COMMON BLOCKS
*/

@import	
	"common/grid",
	"common/templates",
	"common/layout",
	"common/header",
	"common/footer",
	"common/article",
	//"common/masonry",
	"common/list";

/*
* DESIGN COMPONENTS
*/

@import
	"elements/button",
	"elements/input",
	//"elements/input-date",
	//"elements/input-file",
	//"elements/input-range",
	//"elements/input-time",
	//"elements/select",
	"elements/textarea";
	//"elements/blockquote";

@import
   "blocks/popup",
	"blocks/breadcrumb-nav",
	//"blocks/checkbox",
	//"blocks/switch",
	//"blocks/radio",
	"blocks/form",
	"blocks/contact-form",
	"blocks/contact",
	"blocks/search",
	"blocks/main-nav",
	"blocks/nav",
	"blocks/social";
	//"blocks/tags",
	//"blocks/figure";
