
.page-footer {
	text-align: center;
	background-color: $color-dark;
	color: #ffffff;
	padding: 2em 0;
	border-top: 1px #5F5F62 solid;

	@include link {
		color: #ffffff;
	}
}
