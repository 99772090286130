
.button {
	border-radius: $button-radius;
	padding: 5px 2em;
	margin: 0;
	min-height: $form-item-standard-size;
	background-color: $button-bg;
	color: $button-fg;
	text-align: center;
	transition: background-color .15s ease, box-shadow .15s ease;
	position: relative;

	&:not([disabled]) {
		&:hover,
		&:focus,
		&:active {
			z-index: 3;
			outline: 0;
			box-shadow: 0 5px 20px $button-bg;

			&.-inverted {
				box-shadow: 0 5px 20px $button-fg;
			}
		}
	}

	&[disabled] {
		color: #999999;
		background-color: #ebebeb;
		cursor: not-allowed;
	}

	&.-icon {
		&::before {
			vertical-align: middle;
			margin-right: .75em;
			display: inline-block;
		}
	}

	&.-block {
		display: block;
		width: 100%;
	}

	&.-large {
		font-size: 1.15rem;
		padding: 1em 3em;
	}

	&.-inverted {
		background-color: $button-fg;
		color: $button-bg;
	}

	&.-primary {
		background-color: $button-primary-bg;
		box-shadow: 0 0 0 0 $button-primary-bg;
		color: $button-primary-fg;

		&.-inverted {
			background-color: $button-primary-fg;
			box-shadow: 0 0 0 0 $button-primary-fg;
			color: $button-primary-bg;
		}

		&:not([disabled]) {
			&:hover,
			&:focus,
			&:active {
				box-shadow: 0 5px 20px $button-primary-bg;

				&.-inverted {
					box-shadow: 0 5px 20px $button-primary-fg;
				}
			}
		}
	}
}
