[class*="lazy-"] {
	transition: filter .5s ease-out;
	filter: blur(20px);
}

[class*="lazy-"]:not(.loaded) {
	background: #dedede;
}

[class*="lazy-"].loaded {
	filter: blur(0);
}

body {
	color: #383433;
}

::selection {
	background-color: #1a86d0;
	color: #ffffff;
}

.main-wrapper {
	@include bp("> 800px") {  
		@include flex(df, fdrr);
	}
}

.main {
	padding-top: 2em;
	padding-bottom: 4em;

	@include bp("> 800px") {
		padding-left: 7vw;
		flex-grow: 1;
	}
}

.table-wrapper {
	max-width: 100%;
	overflow-x: auto;
	margin-bottom: 2em;

	table {
		margin-bottom: 1em;
	}
}

.home {
	display: flex;
	flex-direction: column;
}

.sidebar {
	background-color: $color-secondary;
	padding-top: 2em;
	padding-bottom: 2em;
	min-width: 340px;
	position: relative;
	@include pad($color-secondary);
	@include pad($color-secondary, right, after);

	@include bp("> 800px") {
		width: ratio($max-width, 480px);
		flex-shrink: 0;

		&::after {
			content: normal;
		}
	}
}

.content {
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
}

.additional {
	background-color: $color-dark;
	color: #ffffff;
	padding: 10vw 0 8vw;

	@include link {
		color: #ffffff;
		text-decoration: underline;
	}

	@include link-over {
		text-decoration: none;
	}

	@include bp("> 800px") {
		padding: 5vw 0 3vw;
	}
}

.section {
	padding: 10vw 0;

	.element-type-heading {
		font-size: rem(50px);
		text-transform: uppercase;
		color: #292A2D;
		text-align: center;
	}

	&.-board .element-type-heading {
		color: $color-tertiary
	}

	@include bp("> 800px") {
		padding: 5vw 0;
	}

	@include bp("> #{$max-width}") {
		padding: 4em 0;
	}

	&.-news {
		background-color: #f3f3f3;
	}
}

.centered {
    max-width: $max-width;
	width: 100%;
	margin-left: auto;
    margin-right: auto;
    
	&.-narrow {
		max-width: 920px;
	}

	&.-padded {
		padding-left: 4%;
		padding-right: 4%;
	}

	&.-padded-left {
		padding-left: 4%;
	}

	&.-padded-right {
		padding-right: 4%;
	}
}


.flair { 
	//height: 0;
	//padding-bottom: ratio(1920px, 666px);
    background: #292a2d url(/images/local/v-202103291600/bg-board.jpg) no-repeat 50% 0 / 100% auto;
    + .section.-board{
        padding: 0;
    }
}

.more {
	text-align: center;

	&__button {
		background-color: $color-brand;
		padding: 1em 2em;
		text-transform: uppercase;
		font-weight: bold;
		display: inline-block;
		font-size: rem(17px);
	}
}

.news {
	.list {
		display: flex;
		flex-wrap: wrap;
		padding-left: 0;
		padding-right: 0;
		margin: 0 -15px 2em;

		&__item {
			flex-grow: 0;
			display: flex;
			padding: 15px;
			@include grid(600px 2, 800px 3);
		}

		.card {
			flex-grow: 1;
		}

		@include min(1200px) {
			margin: 0 #{-$grid-spacing} 3em;

			&__item {
				padding: $grid-spacing;
			}
		}
	}
}

.board {
	.list {
		display: flex;
		flex-wrap: wrap;
		padding-left: 0;
		padding-right: 0;
		margin: 0 0 3em;
		overflow: hidden;

		&__item {
			@include grid(650px 2);
			flex-grow: 0;
			padding: 0;
			margin: 0;
			display: flex;
         flex-direction: column;

         > :only-child {
            flex-grow: 1;
         }
		}

		.board-card {
			flex-grow: 1;
		}
	}
}

@include link(".more__button") {
	color: #ffffff;
	text-decoration: none;
}

@include link-over(".more__button") {
	background-color: $color-tertiary;
}


.text-component {
    margin-bottom: 2em;
    overflow: hidden; 

	p:last-child {
		margin-bottom: 0;
    }
        
    .image-align-left,
    .image-align-right{ 
        margin: 0;
        img{
            @include min(500px){
                max-width: 42.5%;
            }
        }
    }

    .image-align-left {
        img{
            float: left;
            margin: 0 1em .75em 0;
        }
        > *:first-of-type img{
            clear: both;
        }
    }

    .image-align-right{
        img{
            float: right;
            margin: 0 0 .75em 1em;
        }
        > *:first-of-type img{
            clear: both;
        }
    }

    .image-align-center{ 
        text-align: center;
        img{
            margin: 0 0 .75em;
            max-width: 100%;
        }
    }
    [contenteditable="true"] {
        .images-container,
        .image{
            &:focus,
            &:hover{
                img{
                    box-shadow: 0 0 5px red;
                }        
            }
        } 
    }
}

.introduction {
	display: flex;
	flex-direction: column;

	@include bp("> 800px") {
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
	}

	&__flair {
		@include bp("> 800px") {
			width: ( 100% / 3 );
			padding-right: 3em;
			flex-shrink: 0;
		}
	}

	&__image {
		border-radius: 50%;
		flex-grow: 0;
	}

	&__content {
		padding-top: 1em;
		max-width: 820px;
	}

	&__heading {
		font-size: rem(44px);
		font-weight: 600;
		color: $color-tertiary;
	}

	&__text {
		font-size: rem(17px);
		line-height: ( 28 / 17 );
	}
}


.additional {
	&__content {
		display: flex;
		flex-wrap: wrap;
	}

	&__section {
		flex-basis: 100%;
		min-width: 20em;
		margin-bottom: 2.5em;

		@include min(75em) {
			flex-basis: 30%;
			margin-right: 5%;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	.element-type-heading {
		text-transform: uppercase;
		font-size: rem(35px);
		color: #ffffff;
		font-weight: 600;
		margin-bottom: 1.25em;
	}
}

.breadcrumbs {
	background-color: $color-dark;
	text-align: right;
	color: #ffffff;
	padding-top: 1em;
	padding-bottom: 1.1em;
}

.attachments {
	margin: 0 0 3em;
	grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
	grid-gap: 3rem 1.5rem;
}
