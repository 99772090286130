
.breadcrumb-nav {
	margin-top: 0;
	margin-bottom: 0;

	&__item {
		display: inline;


		&:not(.-active)::after {
			content: " \>";
		}
	}

	&__link {
		text-decoration: underline;
		display: inline-block;

		&.-active {
			pointer-events: none;
			text-decoration: none;
			font-weight: normal;
		}
	}

	@include link(".breadcrumb-nav__link") {
		color: #ffffff;
		text-decoration: underline;
	}

	@include link-over(".breadcrumb-nav__link") {
		text-decoration: none;
	}
}
