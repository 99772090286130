
.contact-form {
	padding-top: 0;

	.form__section {
		margin-bottom: .5em;
	}

	&__input,
	&__textarea {
		background-color: #3E3E3E;
		border-color: #3E3E3E;
		color: #ffffff;
		border-radius: 1px;
	}

	&__input {
		min-height: 55px;
	}

	&__info:first-child {
		margin: .55em 0 .9em;
	}

	&__button {
		border-radius: 1px;
		color: #ffffff;
		text-transform: uppercase;

		&.-primary {
			color: #ffffff;
			font-weight: bold;
			font-size: rem(17px);
		}
	}
}
