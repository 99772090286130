
//Tělo dokumentu
.article {
	&__heading {
		font-size: rem(50px);
		letter-spacing: -.02em;
		line-height: 1.15;
		margin: .75em 0;
		font-weight: 600;
		color: #187ac9;
	}

	// &__header {
	// 	margin-bottom: 3em;
	// }

	&__footer {
		margin: 2em 0;
		padding: 2em 0 0;
		border-top: 1px #cccccc solid;

		div {
			display: inline-block;

			input[type="date"],
			input[type="time"] {
				display: inline;
				border: 0;
				padding: 0 .5em;
				border-radius: 3px;
			}
		}

		.invalid ul {
			margin: 0;
			padding: 0 0 0 .5em;
		}
	}

	&__perex {
		font-size: 1.1em;
		margin-bottom: 1.5em;
	}

	&__image {
		margin-left: auto;
		margin-right: auto;
	}

	&__body {
		margin-bottom: 4em;
	}

	&__author {
		text-align: left;
		clear: both;
		margin: 1.5em 0;
		display: inline;
		font-style: normal;

	}

	&__datespan {
		margin-bottom: 3em;
	}

	&__date {
		font-weight: bold;
	}
}
